

export default {
    methods: {
        getActsShutters(props, callback=() => false) {
            return {
                'horse-selection': {
                    name: 'horse-selection',
                    title: this.$t('acte.choix_chevaux'),
                    cmpPath: 'components/Horse/HorseSelectionV2',
                    onOk: (horse_ids) => {
                        this.openActAddForm(horse_ids, props.group_preselect)
                    },
                    props
                },
				'act-history': {
                    name: 'act-history',
                    title: this.$t('global.actes'),
                    cmpPath: 'components/ShutterScreens/Act/HistoryAct',
                    props
                },
                'act-list': {
                    name: 'act-list',
                    title: this.$t('acte.vos_actes'),
                    cmpPath: 'components/ShutterScreens/Act/PlanningAct',
                    props
                },
                'act-history-beta': {
                    name: 'act-history',
                    title: this.$t('global.actes'),
                    cmpPath: 'components/ShutterScreens/Act/HistoryActbeta',
                    props
                },
				'act-horse': {
					name: 'act-horse',
                    title: this.$t('global.actes'),
                    cmpPath: 'components/ShutterScreens/Horse/HorseActes',
                    props,
					noClass: true
				},
                'act-add-form': {
                    name: 'act-add-form',
                    title: this.$t('acte.ajouter_acte'),
                    cmpPath: 'components/WriteActe',
                    onOk: this.onShuttersSubmit,
                    props
                },
                'act-edit': {
                    name: 'act-edit',
                    title: this.$t('acte.modifier_acte'),
                    cmpPath: 'components/WriteActe',
                    onOk: this.onShuttersSubmit,
                    props
                },
                'act-valid': {
                    name: 'act-valid',
                    title: this.$t('acte.valider_acte'),
                    cmpPath: 'components/WriteActe',
                    onOk: this.onShuttersSubmit,
                    props
                },
				'all-act-horse': {
					name: 'all-act-horse',
                    title: this.$t('global.all_actes'),
                    cmpPath: 'components/ShutterScreens/Horse/HorseAllActes',
                    props,
				},
                'task-planning': {
                    name: 'task-planning',
                    title: this.$t('global.planning_task_todo'),
                    cmpPath: 'components/ShutterScreens/Act/TaskPlanning',
                    props,
                },
                'task-planning-add': {
                    name: 'task-planning-add',
                    title: this.$t('global.planning_task_add'),
                    cmpPath: 'components/ShutterScreens/Act/WriteTaskPlanning',
                    props,
                    onOk: callback
                },
            }
        },

		setupActHistory(actesTypes=[]) {
            const shutters = this.getActsShutters({
                actesTypes
            })
            this.shutterPanel().open(shutters['act-history'])
        },

        setupActList(actesTypes=[], start_date=null) {
            const shutters = this.getActsShutters({
                actesTypes,
                start_date
            })
            this.shutterPanel().open(shutters['act-list'])
        },

		setupHorseActes(horse_id, acte_statut=0, button_action_event_only=false, start_date=null, horse_name=false) {
            const shutters = this.getActsShutters({
                horse_id, 
				acte_statut,
                button_action_event_only,
                start_date,
                horse_name
            })
            this.shutterPanel().open(shutters['act-horse'])
        },

		setupHorseAllActes(horse_id) {
            const shutters = this.getActsShutters({
                horse_id
            })
            this.shutterPanel().open(shutters['all-act-horse'])
        },

        setupActAdd(horse_id, group_preselect = '', type_preselect = '') {
            let horses_ids
            if(horse_id) horses_ids = horse_id.split(',')

            const shutters = this.getActsShutters({
                horses_ids,
                group_preselect,
                type_preselect
            })

            if(!horses_ids) {
                this.shutterPanel().open(shutters['horse-selection'])
            }
            else {
                this.shutterPanel().open(shutters['act-add-form'])
            }
        },

        openActAddForm(horses_ids, group_preselect) {
            if (horses_ids) {
                const shutters = this.getActsShutters({
                    horses_ids,
                    group_preselect
                })
                this.shutterPanel().open(shutters['act-add-form'])
            }
        },

        setupActEdit(actes_ids, lot) {
            const shutters = this.getActsShutters({
                actes_ids: actes_ids ? actes_ids.split(',') : [],
                lot: lot
            })
            this.shutterPanel().open(shutters['act-edit'])
        },

        setupActValidation(actes_ids) {
            const shutters = this.getActsShutters({
                actes_ids: actes_ids ? actes_ids.split(',') : [],
                validation: true
            })
            this.shutterPanel().open(shutters['act-valid'])
        },

        setupActDuplication(acte) {
            const shutters = this.getActsShutters({
                acte_duplicate: acte
            })

            this.shutterPanel().open(shutters['act-add-form'])
        },

        setupTaskPlanning() {
            const shutters = this.getActsShutters({})

            this.shutterPanel().open(shutters['task-planning'])
        },

        setupAddTaskPlanning(horse, date, planning, callback) {
            const shutters = this.getActsShutters({horse, date, planning}, callback)

            this.shutterPanel().open(shutters['task-planning-add'])
        },

        setupEditTaskPlanning(task, horse, planning, callback) {
            const shutters = this.getActsShutters({task_edit: task, horse, planning}, callback)

            this.shutterPanel().open(shutters['task-planning-add'])
        }
    }
}
