var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.reloadingTransformer)?_c('CustomTable',{ref:"table",attrs:{"id_table":_vm.tableId,"transformer":_vm.Transformer,"base-filters":_vm.filters,"busy":_vm.table_busy,"primaryKey":"actes_id","display_action_button":!_vm.horseFicheReadOnly,"checkboxes":!_vm.horseFicheReadOnly,"date-filter":_vm.dateFilter,"persistDateFilter":true,"externSlotColumns":['notes.type_fonts', 'ordonnance', 'is_validated'],"hrefsRoutes":_vm.config_table_hrefs},on:{"update:transformer":function($event){_vm.Transformer=$event},"update:busy":function($event){_vm.table_busy=$event}},scopedSlots:_vm._u([{key:"custom-slot-cell(notes.type_fonts)",fn:function(ref){
var data = ref.data;
return [(data.notes && Object.keys(data.notes.type_fonts).length > 0)?_c('div',{staticClass:"previewColIdentifier",on:{"click":function($event){return _vm.catchColumnNotesShutter(data)}}},_vm._l((data.notes.type_fonts),function(font,key){return _c('span',{key:key},[_c('font-awesome-icon',{attrs:{"icon":font.font}}),_vm._v(" "+_vm._s(font.occurences)+" ")],1)}),0):_vm._e()]}},{key:"custom-slot-cell(ordonnance)",fn:function(ref){
var data = ref.data;
return [(data.actes_document_lien.length > 0)?_c('div',_vm._l((data.actes_document_lien),function(document){return _c('div',{key:document.document_id},[_c('a',{attrs:{"href":"#"},on:{"click":function($event){return _vm.viewDocumentOrdonnance(document)}}},[_vm._v(" "+_vm._s(document.document_name)+" ")])])}),0):(data.actetype.actestype_needordonnance)?_c('div',[_c('a',{attrs:{"href":"#"},on:{"click":function($event){return _vm.addOrdonnance([data])}}},[_c('font-awesome-icon',{attrs:{"icon":['far', 'file-medical-alt']}})],1)]):_vm._e()]}},{key:"custom-slot-cell(is_validated)",fn:function(ref){
var data = ref.data;
return [(data.actes_actesstatut == 1)?_c('font-awesome-icon',{staticClass:"text-success",attrs:{"icon":['fal', 'check-circle']}}):_c('font-awesome-icon',{staticClass:"text-danger",attrs:{"icon":['fal', 'times-circle']}})]}}],null,true)}):_vm._e(),_c('ModalDemandeAnalyse',{ref:"modal_demande_analyse"}),_c('ModalAskPaillettes',{ref:"modal_ask_paillettes",attrs:{"callback_confirm":_vm.goToDeleteActes}}),_c('b-modal',{ref:"modalViewDocumentOrdonnance",attrs:{"modal-class":'modal-pdf',"size":"xl","hide-footer":""},scopedSlots:_vm._u([{key:"modal-header",fn:function(ref){
var close = ref.close;
return [_c('h2',{staticClass:"mx-auto my-auto"},[_vm._v(_vm._s(_vm.$t('ordonnance.document_visualisation')))]),_c('i',{staticClass:"btn btn-link my-auto",on:{"click":function($event){return close()}}},[_c('font-awesome-icon',{attrs:{"icon":['fal', 'times-circle']}})],1)]}}])},[_c('OfflineMedia',{attrs:{"filename":_vm.modal_document_filename,"customClass":"w-100"}})],1),_c('b-modal',{ref:"ordonnanceModal",attrs:{"size":"xl","hide-footer":""},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t("ordonnance.ajouter_ordonnance"))+" ")]},proxy:true}])},[_c('OrdonnanceForm',{attrs:{"horses_ids":_vm.horse_ids,"from_mediatheque":false},on:{"documents":_vm.handleOrdoForm}})],1),_c('b-modal',{ref:"ordonnanceRenameModal",attrs:{"size":"xl","hide-footer":""},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t("ordonnance.ajouter_ordonnance"))+" ")]},proxy:true}])},[_c('OrdonnanceRename',{attrs:{"horses_ids":_vm.horse_ids,"from_mediatheque":false,"documents":_vm.documents,"acte_ids":_vm.acte_ids},on:{"update:documents":function($event){_vm.documents=$event},"success":_vm.HandleOrdoRename}})],1),_c('ModalReplan',{ref:"modal_actes_replan",on:{"ok":_vm.applyReplanActe}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }