import Transformer from './Transformer'


export default class SemenceLocalisationTransformer extends Transformer {

	table = 'semence_localisation'

	async children (loc) {
		return this.db().t('semence_localisation')
		.then(table => {
			return table.where({
				semencelocalisation_parent: loc.semencelocalisation_id
			}).sortBy('semencelocalisation_order')
		})
		.then(async array => {
			return await SemenceLocalisationTransformer.process(array)
		})
	}

	async fetch (col) {
		this.additionalColumns({
			children: this.children
		})

		return col
	}

    async transform (loc) {
        return {
			semencelocalisation_id		: loc.semencelocalisation_id,
			semencelocalisation_libelle	: loc.semencelocalisation_libelle,
			semencelocalisation_parent	: loc.semencelocalisation_parent,
			semencelocalisation_order	: loc.semencelocalisation_order,
			children					: loc.children
        }
    }

    async transformWithoutChildren (loc) {
    	return {
    		semencelocalisation_id		: loc.semencelocalisation_id,
			semencelocalisation_libelle	: loc.semencelocalisation_libelle,
			semencelocalisation_parent	: loc.semencelocalisation_parent,
			semencelocalisation_order	: loc.semencelocalisation_order,
    	}
    }
}
